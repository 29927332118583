@import "alembic";

$spacing-unit:     30px;
$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

.feature {
  background-position: left;
  background-color: #B54343;
}
.logo img {
  max-height: 2rem;
}

.post-navigation {
    margin-top: $spacing-unit;
    padding-top: $spacing-unit;
    border-top: 1px solid $grey-color-light;
    display: flex;
    justify-content: space-between;
}

.share ul {
  display: flex;
  list-style: none;
  & > li {
    margin-right: .3rem;
    & * {
      vertical-align: bottom;
    }
  }
}
